// src/components/UserProfile/UserProfile.js

import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import "./UserProfile.css";
import { useCart } from "../CartContext/CartContext";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  format,
  isToday,
  isAfter,
  addDays,
  startOfDay,
  isBefore,
  compareAsc,
  isSameDay
} from "date-fns";
import { useNavigate } from "react-router-dom";
import moment from "moment";

const UserProfile = () => {
  const [userDetails, setUserDetails] = useState({
    name: "",
    email: "",
    role: "",
    user_id: ""
  });

  const [decodedToken, setDecodedToken] = useState({});
  const [formCompleted, setFormCompleted] = useState(false);
  const [userClasses, setUserClasses] = useState([]);
  const [userFullName, setUserFullName] = useState("");
  const [showDropdown, setShowDropdown] = useState(false);
  const [activeDropdownClassId, setActiveDropdownClassId] = useState(null);
  const todayClassesRef = useRef(null);
  const websiteFeaturesRef = useRef(null);
  const staffFeaturesRef = useRef(null);
  const { addToCart } = useCart();
  const [newTodayClasses, setNewTodayClasses] = useState([]);
  const [newTomorrowClasses, setNewTomorrowClasses] = useState([]);
  const navigate = useNavigate();

  // New state variables for cancellation messages
  const [message, setMessage] = useState(null);
  const [error, setError] = useState(null);

  // Remove fixed timezone and parse as UTC, then convert to local
  const parseEventTimeAsLocal = (timeString) => {
    // Parse the time string as UTC and convert to local time
    return moment.utc(timeString).local().toDate();
  };

  const hasClassStarted = (classStartTime) => {
    const now = new Date();
    const startTime = parseEventTimeAsLocal(classStartTime);
    return now > startTime;
  };

  // Axios instance with baseURL
  const axiosInstance = axios.create({
    baseURL: "https://www.ptemployment.org/api"
  });

  // Interceptor to attach JWT token
  axiosInstance.interceptors.request.use(
    (config) => {
      const token = localStorage.getItem("token");
      if (token) {
        config.headers["Authorization"] = `Bearer ${token}`;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  // Fetch user details and form completion status
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      try {
        const decoded = jwtDecode(token);
        setUserDetails({
          name: decoded.username || decoded.name || "",
          email: decoded.email || "",
          role: decoded.role || "User",
          user_id: decoded.user_id || ""
        });

        // **Ensure role is stored in localStorage**
        localStorage.setItem("role", decoded.role || "User");

        const checkFormCompletionEndpoint = `/check-form-completion?user_id=${decoded.user_id}`;

        console.log(
          "Checking form completion from:",
          checkFormCompletionEndpoint
        );
        axiosInstance
          .get(checkFormCompletionEndpoint)
          .then((response) => {
            console.log("Form completion response:", response.data);
            setFormCompleted(response.data.isCompleted);
            if (
              response.data.isCompleted &&
              response.data.firstName &&
              response.data.lastName
            ) {
              setUserFullName(
                `${response.data.firstName} ${response.data.lastName}`
              );
            }
          })
          .catch((error) =>
            console.error("Error fetching form status:", error)
          );
        console.log("User role set in UserProfile.js:", decoded.role);
      } catch (error) {
        console.error("Error decoding token:", error);
      }
    }
  }, []);

  // Fetch user classes
  useEffect(() => {
    const fetchUserClasses = async () => {
      try {
        const userClassesEndpoint = "/user-classes"; // Relative URL
        console.log("Fetching user classes from:", userClassesEndpoint);
        const response = await axiosInstance.get(userClassesEndpoint);

        const today = startOfDay(new Date());
        const sevenDaysLater = addDays(today, 7);

        let filteredClasses = response.data.filter((classItem) => {
          const classStartTime = parseEventTimeAsLocal(classItem.start_time);
          return (
            isAfter(classStartTime, today) &&
            isBefore(classStartTime, sevenDaysLater)
          );
        });

        // Sort classes by start time
        filteredClasses.sort((a, b) =>
          compareAsc(
            parseEventTimeAsLocal(a.start_time),
            parseEventTimeAsLocal(b.start_time)
          )
        );

        setUserClasses(filteredClasses);
      } catch (error) {
        console.error("Error fetching user classes:", error);
      }
    };

    fetchUserClasses();
  }, []);

  // Fetch all classes for today and tomorrow
  useEffect(() => {
    const fetchAllClasses = async () => {
      try {
        const allClassesEndpoint = "/classes/class-schedules"; // Relative URL
        console.log("Fetching all classes from:", allClassesEndpoint);
        const responseAllClasses = await axiosInstance.get(allClassesEndpoint);

        const today = startOfDay(new Date());
        const tomorrow = addDays(today, 1);

        let newFilteredTodayClasses = responseAllClasses.data.filter(
          (classItem) =>
            isToday(parseEventTimeAsLocal(classItem.start_time)) &&
            !userClasses.some((userClass) => userClass.id === classItem.id)
        );

        let newFilteredTomorrowClasses = responseAllClasses.data.filter(
          (classItem) =>
            isSameDay(parseEventTimeAsLocal(classItem.start_time), tomorrow) &&
            !userClasses.some((userClass) => userClass.id === classItem.id)
        );

        setNewTodayClasses(newFilteredTodayClasses);
        setNewTomorrowClasses(newFilteredTomorrowClasses);
      } catch (error) {
        console.error("Error fetching all classes:", error);
      }
    };

    fetchAllClasses();
  }, [userClasses]);

  const handleScrollToRef = (ref) => () =>
    ref.current?.scrollIntoView({ behavior: "smooth" });

  const toggleDropdown = () => setShowDropdown(!showDropdown);

  const handleInvoicingClick = () => {
    const token = localStorage.getItem("token");
    if (token) {
      navigate("/invoicing", { state: { token } });
    } else {
      console.error("No token found. Cannot navigate to Invoicing page.");
    }
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    navigate("/");
  };

  const handleParticipationFormClick = () => {
    navigate("/participationForm");
  };

  const handleAdminDashboardClick = () => {
    navigate("/adminDashboard");
  };

  const handleAddToCart = (classItem) => {
    console.log("Adding class to cart:", classItem);
    addToCart(classItem);
  };

  const handleClassSchedulerClick = () => {
    navigate("/classScheduler");
  };

  const todayClasses = userClasses.filter((classItem) =>
    isToday(parseEventTimeAsLocal(classItem.start_time))
  );

  const renderClassItem = (classItem) => (
    <li key={classItem.id} className="class-list-item">
      <span className="class-title-text">{classItem.title}</span> -{" "}
      <span className="class-time-text">
        {format(parseEventTimeAsLocal(classItem.start_time), "p")}
      </span>
      <button
        className="add-to-cart-button btn btn-success btn-sm ml-2"
        onClick={() => addToCart(classItem)}
      >
        Add to Cart
      </button>
    </li>
  );

  const formatDateTimeForGoogle = (dateTime) => {
    const parsedDateTime = parseEventTimeAsLocal(dateTime);
    return moment(parsedDateTime).format("YYYYMMDDTHHmmss");
  };

  const createGoogleCalendarUrl = (classItem) => {
    const startTime = formatDateTimeForGoogle(classItem.start_time);
    const endTime = formatDateTimeForGoogle(classItem.end_time);
    const details = encodeURIComponent(classItem.description || "Class Event");
    const location = encodeURIComponent("Virtual Event");
    const title = encodeURIComponent(classItem.title);
    const timeZone = moment.tz.guess(); // Get the user's local timezone

    return `https://calendar.google.com/calendar/r/eventedit?text=${title}&dates=${startTime}/${endTime}&details=${details}&location=${location}&ctz=${encodeURIComponent(
      timeZone
    )}`;
  };

  const createOutlookCalendarUrl = (classItem) => {
    const startTime = parseEventTimeAsLocal(classItem.start_time).toISOString();
    const endTime = parseEventTimeAsLocal(classItem.end_time).toISOString();
    const title = encodeURIComponent(classItem.title);
    const details = encodeURIComponent(
      classItem.description || "Class details"
    );
    const location = encodeURIComponent("Class location or 'Online'");

    return `https://outlook.live.com/owa/?path=/calendar/action/compose&startdt=${startTime}&enddt=${endTime}&subject=${title}&body=${details}&location=${location}`;
  };

  const createICalendarData = (classItem) => {
    const startTime = moment(parseEventTimeAsLocal(classItem.start_time))
      .format("YYYYMMDDTHHmmss")
      .toUpperCase();
    const endTime = moment(parseEventTimeAsLocal(classItem.end_time))
      .format("YYYYMMDDTHHmmss")
      .toUpperCase();
    const title = classItem.title;
    const description = classItem.description || "Class details";
    const location = "Class location or 'Online'";

    return `BEGIN:VCALENDAR
VERSION:2.0
BEGIN:VEVENT
SUMMARY:${title}
DTSTART:${startTime}
DTEND:${endTime}
DESCRIPTION:${description}
LOCATION:${location}
END:VEVENT
END:VCALENDAR`;
  };

  const handleAddToCalendarClick = (classId) => {
    setActiveDropdownClassId((prevId) => (prevId === classId ? null : classId));
  };

  // Updated function to handle class cancellation with specific error message
  const handleCancelClass = async (classId) => {
    const confirmCancel = window.confirm(
      "Are you sure you want to cancel this class?"
    );
    if (!confirmCancel) return;

    setMessage(null);
    setError(null);
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        setError("You are not authenticated. Please log in again.");
        return;
      }

      const cancelEndpoint = `/user-classes/${classId}/cancel`; // Relative URL

      const response = await axiosInstance.delete(cancelEndpoint, {
        headers: { Authorization: `Bearer ${token}` }
      });

      if (response.status === 200) {
        setMessage("Class successfully canceled.");
        // Remove the canceled class from userClasses
        setUserClasses((prevClasses) =>
          prevClasses.filter((classItem) => classItem.id !== classId)
        );
      }
    } catch (err) {
      // Access the correct error message key
      if (err.response && err.response.data && err.response.data.error) {
        const backendError = err.response.data.error;
        // Check if the error is due to the cancellation window
        if (
          backendError.includes(
            "Cannot cancel class enrollment less than 24 hours"
          )
        ) {
          setError(
            "You cannot cancel a class less than 24 hours before its start time."
          );
        } else {
          // For other backend errors, display the backend message
          setError(backendError);
        }
      } else {
        // For network or unexpected errors, display a generic message
        setError("An error occurred while canceling the class.");
      }
    }
  };

  const tomorrow = addDays(new Date(), 1);
  const tomorrowClasses = userClasses.filter((classItem) =>
    isSameDay(parseEventTimeAsLocal(classItem.start_time), tomorrow)
  );

  return (
    <div className="user-profile container">
      <div className="quick-links mb-4">
        <span className="mr-2">Quick Links:</span>
        <button
          className="btn btn-link"
          onClick={handleScrollToRef(todayClassesRef)}
        >
          Today's Classes
        </button>
        <button
          className="btn btn-link"
          onClick={handleScrollToRef(websiteFeaturesRef)}
        >
          Website Features
        </button>
        {(userDetails.role === "Administrator" ||
          userDetails.role === "Staff") && (
          <button
            className="btn btn-link"
            onClick={handleScrollToRef(staffFeaturesRef)}
          >
            Staff Features
          </button>
        )}
        <button className="btn btn-danger ml-3" onClick={handleLogout}>
          Logout
        </button>
      </div>
      <h1 className="mb-4">
        {userFullName ? (
          <>
            <span className="role-highlight">{userFullName}'s</span> Profile
          </>
        ) : (
          "User Profile"
        )}
      </h1>
      <div className="user-details mb-4">
        <p>
          <strong>Username:</strong> {userDetails.name}
        </p>
        <p>
          <strong>Email:</strong> {userDetails.email}
        </p>
        {userDetails.role !== "User" && (
          <p className="role-highlight">
            <strong>Role:</strong> {userDetails.role}
          </p>
        )}
      </div>
      {/* Interest Promotion Section */}
      <div className="interest-promotion mb-5">
        <p className="interest-promotion-heading">
          Interested in joining a class today or tomorrow? Explore available
          options!
        </p>
        <div className="class-options row">
          {/* Today's Classes Section */}
          <div className="today-classes col-12 col-md-6 mb-4">
            <h3>Today's Classes:</h3>
            {newTodayClasses.length > 0 ? (
              <ul className="list-unstyled">
                {newTodayClasses
                  .filter((classItem) => !hasClassStarted(classItem.start_time))
                  .map((classItem) => renderClassItem(classItem))}
              </ul>
            ) : (
              <p>
                All available classes for today have been signed up for or are
                no longer available. Please check back for future classes or
                explore other days for more opportunities.
              </p>
            )}
          </div>

          {/* Tomorrow's Classes Section */}
          <div className="tomorrow-classes col-12 col-md-6 mb-4">
            <h3>Tomorrow's Classes:</h3>
            {newTomorrowClasses.length > 0 ? (
              <ul className="list-unstyled">
                {newTomorrowClasses.map((classItem) =>
                  renderClassItem(classItem)
                )}
              </ul>
            ) : (
              <p>
                All available classes for tomorrow have been signed up for or no
                longer available. Keep an eye on upcoming days for new classes
                to join.
              </p>
            )}
          </div>
        </div>
      </div>
      {/* Additional Section for Class Calendars and Descriptions */}
      <div className="alternative-options-section mb-5">
        <h3>Or:</h3>
        <p className="text-center">Explore more ways to find classes:</p>
        <div className="d-flex justify-content-center">
          <button
            className="btn btn-primary mr-2"
            onClick={() => navigate("/monthlySchedule")}
          >
            Check Our Class Calendar
          </button>
          <button
            className="btn btn-info"
            onClick={() => navigate("/services")}
          >
            Class Descriptions
          </button>
        </div>
        <p className="text-center mt-3">
          Our interactive and colorful class calendar helps you easily find and
          join classes. The class description page provides detailed information
          about the classes.
        </p>
      </div>
      <div className="disclaimer mb-5">
        <p>
          <strong>Disclaimer:</strong> You will not be able to sign up for
          classes unless the participation form is completed.
        </p>
      </div>
      <div className="form-status-section mb-5">
        <h2>Form Status</h2>

        <div
          className={`form-status ${
            formCompleted ? "completed" : "not-completed"
          } p-3`}
        >
          {formCompleted ? "✔️ Form Completed" : "❌ Form Not Completed"}
          <button
            className="btn btn-primary mb-3 ml-3"
            onClick={handleParticipationFormClick}
          >
            Fill Participation Form
          </button>
        </div>
      </div>
      <div className="classes-today mb-5">
        <div ref={todayClassesRef} className="classes-today">
          <h2>
            {userFullName ? (
              <span className="role-highlight">{userFullName}'s</span>
            ) : (
              "User's"
            )}{" "}
            Classes for <span style={{ color: "#28a745" }}>Today</span>
          </h2>
        </div>

        {todayClasses.length > 0 ? (
          todayClasses.map((classItem, index) => (
            <div key={index} className="class-item mb-3 p-3 border rounded">
              <p className="class-title mb-1">
                <strong>{classItem.title}</strong> -{" "}
                <span className="class-time">
                  {format(
                    parseEventTimeAsLocal(classItem.start_time),
                    "MMMM do, yyyy hh:mm a"
                  )}
                </span>
              </p>
              <div className="d-flex align-items-center">
                <button
                  className="add-to-calendar-button btn btn-primary btn-sm mr-2"
                  onClick={() => handleAddToCalendarClick(classItem.id)}
                >
                  Add to Calendar
                </button>
                {activeDropdownClassId === classItem.id && (
                  <div className="calendar-options-dropdown">
                    <a
                      href={createGoogleCalendarUrl(classItem)}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="dropdown-item"
                    >
                      Google Calendar
                    </a>
                    <a
                      href={createOutlookCalendarUrl(classItem)}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="dropdown-item"
                    >
                      Outlook Calendar
                    </a>
                    <a
                      href={`data:text/calendar;charset=utf8,${encodeURIComponent(
                        createICalendarData(classItem)
                      )}`}
                      download={`${classItem.title}.ics`}
                      className="dropdown-item"
                    >
                      iCalendar
                    </a>
                  </div>
                )}
              </div>
            </div>
          ))
        ) : (
          <p>No classes for today.</p>
        )}
      </div>
      <div className="classes-next-7-days mb-5">
        <h2 className="mb-4">
          {userFullName ? (
            <span className="role-highlight">{userFullName}'s</span>
          ) : (
            "User's"
          )}{" "}
          Classes Coming Up in the{" "}
          <span style={{ color: "#28a745" }}>Next 7 Days</span>
        </h2>

        {/* Display success or error messages */}
        {message && (
          <div className="alert alert-success" role="alert">
            {message}
          </div>
        )}
        {error && (
          <div className="alert alert-danger" role="alert">
            {error}
          </div>
        )}

        {userClasses.length > 0 ? (
          userClasses.map((classItem, index) => (
            <div key={index} className="class-item mb-3 p-3 border rounded">
              <p className="class-title mb-1">
                <strong>{classItem.title}</strong> -{" "}
                <span className="class-time">
                  {format(
                    parseEventTimeAsLocal(classItem.start_time),
                    "MMMM do, yyyy hh:mm a"
                  )}
                </span>
              </p>
              <div className="d-flex align-items-center">
                <button
                  className="add-to-calendar-button btn btn-primary btn-sm mr-2"
                  onClick={() => handleAddToCalendarClick(classItem.id)}
                >
                  Add to Calendar
                </button>
                <button
                  className="cancel-class-button btn btn-danger btn-sm mr-2"
                  onClick={() => handleCancelClass(classItem.id)}
                >
                  Cancel Class
                </button>
                {activeDropdownClassId === classItem.id && (
                  <div className="calendar-options-dropdown">
                    <a
                      href={createGoogleCalendarUrl(classItem)}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="dropdown-item"
                    >
                      Google Calendar
                    </a>
                    <a
                      href={createOutlookCalendarUrl(classItem)}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="dropdown-item"
                    >
                      Outlook Calendar
                    </a>
                    <a
                      href={`data:text/calendar;charset=utf8,${encodeURIComponent(
                        createICalendarData(classItem)
                      )}`}
                      download={`${classItem.title}.ics`}
                      className="dropdown-item"
                    >
                      iCalendar
                    </a>
                  </div>
                )}
              </div>
            </div>
          ))
        ) : (
          <p>No classes found for the next 7 days.</p>
        )}
      </div>
      <div className="row mb-5">
        <div className="col-md-6">
          <div ref={websiteFeaturesRef} className="website-features mb-3">
            <h3>Website Features</h3>
            <button
              className="btn btn-info d-block mb-2"
              onClick={() => navigate("/monthlySchedule")}
            >
              Monthly Calendar
            </button>
            <button
              className="btn btn-info d-block mb-2"
              onClick={() => navigate("/services")}
            >
              Class Description
            </button>
            <button
              className="btn btn-info d-block mb-2"
              onClick={() => navigate("/myClasses")}
            >
              My Classes
            </button>
          </div>
        </div>
        <div className="col-md-6">
          <h3>Website Information</h3>
          <button
            className="btn btn-success d-block mb-2"
            onClick={() => navigate("/aboutUs")}
          >
            About Us
          </button>

          <button
            className="btn btn-success d-block mb-2"
            onClick={() => navigate("/contact")}
          >
            Contact Us
          </button>

          <button
            className="btn btn-warning d-block mb-5"
            onClick={() => navigate("/policies")}
          >
            Policies
          </button>
        </div>
      </div>
      {(userDetails.role === "Administrator" ||
        userDetails.role === "Staff") && (
        <div className="staff-features-section mb-5">
          <h3>Staff Features</h3>
          {["Administrator", "Staff"].includes(userDetails.role) && (
            <div ref={staffFeaturesRef} className="staff-features-section">
              <button
                className="btn btn-secondary d-block mb-2"
                onClick={() => navigate("/classScheduler")}
              >
                Class Scheduler
              </button>
            </div>
          )}
          <button
            className="btn btn-secondary d-block mb-2"
            onClick={() => navigate("/classLists")}
          >
            Class Lists
          </button>
        </div>
      )}
      {userDetails.role === "Administrator" && (
        <div className="admin-features-section mb-3 admin-dashboard-spacing">
          <h3>Administration Features</h3>
          <button
            className="btn btn-dark d-block mb-2"
            onClick={handleAdminDashboardClick}
          >
            Admin Dashboard
          </button>
          <button
            className="btn btn-dark d-block mb-2"
            onClick={handleInvoicingClick}
          >
            Invoicing
          </button>
        </div>
      )}
      <button className="btn btn-danger mb-5" onClick={handleLogout}>
        Logout
      </button>
    </div>
  );
};

export default UserProfile;
