// src/components/Invoicing/Invoicing.js

import React, { useState, useEffect } from "react";
import axios from "axios";
import { useLocation } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Invoicing.css";
import moment from "moment";

// **Define the Backend Base URL**
const API_BASE_URL =
  window.location.hostname === "localhost"
    ? "http://localhost:3000/api" // Development backend
    : "/api"; // Production backend

const Invoicing = () => {
  // ---------------------------------------
  // **State Variables**
  // ---------------------------------------
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [events, setEvents] = useState([]);
  const [allEvents, setAllEvents] = useState([]); // State for All Events
  const [currentMonth, setCurrentMonth] = useState(moment().startOf("month"));
  const [isEditingBillingInfo, setIsEditingBillingInfo] = useState(false);
  const [isConfirmingEmailAll, setIsConfirmingEmailAll] = useState(false);
  const [isAddingEvent, setIsAddingEvent] = useState(false);
  const [eventToDelete, setEventToDelete] = useState(null);

  // **Separate State for Editing Billing Information**
  const [editBillingInfo, setEditBillingInfo] = useState({
    first_name: "",
    last_name: "",
    billing_code: "",
    address: "",
    phone: "",
    email: ""
  });

  const [billToInfo, setBillToInfo] = useState({
    name: "N/A",
    address: "N/A",
    phone: "N/A",
    email: "N/A"
  });

  // **State for Adding New Event**
  const [newEvent, setNewEvent] = useState({
    title: "",
    price: "",
    description: "", // Optional
    start_time: "",
    end_time: "",
    billing_hours: ""
  });

  const [showConfirmSave, setShowConfirmSave] = useState(false);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [showConfirmAddEvent, setShowConfirmAddEvent] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const location = useLocation();
  const token = location.state?.token || localStorage.getItem("token");

  // **State for Tracking Selected Event for Deletion**
  const [selectedDeleteEventId, setSelectedDeleteEventId] = useState(null);

  // **State for Pagination of Available Events**
  const [currentAvailablePage, setCurrentAvailablePage] = useState(1);
  const eventsPerPage = 5; // Adjust as needed

  // **New State for Search Query**
  const [searchQuery, setSearchQuery] = useState("");

  // **New Function to Handle Search Input**
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  // ---------------------------------------
  // **Data Fetching on Component Mount**
  // ---------------------------------------
  useEffect(() => {
    const fetchUsers = async () => {
      try {
        if (!token) {
          console.error("No JWT token found. Authentication failed.");
          return;
        }

        const response = await axios.get(`${API_BASE_URL}/users`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });

        const usersWithFullNames = await Promise.all(
          response.data.map(async (user) => {
            const userId = user.user_id || user.id;
            const participationResponse = await axios.get(
              `${API_BASE_URL}/check-form-completion?user_id=${userId}`,
              {
                headers: {
                  Authorization: `Bearer ${token}`
                }
              }
            );
            const { firstName, lastName } = participationResponse.data;
            return {
              ...user,
              first_name: firstName,
              last_name: lastName
            };
          })
        );

        setUsers(usersWithFullNames);
      } catch (error) {
        console.error("Error fetching users or participation details:", error);
        setErrorMessage(
          error.response?.data?.error ||
            "There was an error fetching users. Please try again."
        );
        setShowErrorMessage(true);
      }
    };

    fetchUsers();
  }, [token]);

  // ---------------------------------------
  // **Calculate Amount Automatically**
  // ---------------------------------------
  useEffect(() => {
    const hours = parseFloat(newEvent.billing_hours);
    const rate = parseFloat(newEvent.price);
    if (!isNaN(hours) && !isNaN(rate)) {
      setNewEvent((prev) => ({
        ...prev,
        amount: (hours * rate).toFixed(2)
      }));
    } else {
      setNewEvent((prev) => ({
        ...prev,
        amount: ""
      }));
    }
  }, [newEvent.billing_hours, newEvent.price]);

  // ---------------------------------------
  // **Handle Register Event**
  // ---------------------------------------
  const handleRegisterEvent = async (event) => {
    if (!selectedUser) {
      alert("Please select a user to register the event.");
      return;
    }

    const userId = selectedUser.user_id || selectedUser.id;
    const eventId = event.id;

    try {
      const response = await axios.post(
        `${API_BASE_URL}/invoicing/events/${eventId}/register`,
        { user_id: userId },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json"
          }
        }
      );

      console.log(`✅ Registered Event ID: ${eventId} to User ID: ${userId}`);
      alert("Event registered to user successfully.");

      // Refresh the user's events to reflect the new registration
      handleUserSelect(selectedUser);
    } catch (error) {
      console.error("Error registering event to user:", error);
      alert(
        error.response?.data?.error ||
          "There was an error registering the event to the user. Please try again."
      );
      setErrorMessage(
        error.response?.data?.error ||
          "There was an error registering the event to the user. Please try again."
      );
      setShowErrorMessage(true);
    }
  };

  // ---------------------------------------
  // **Handle User Selection and Data Fetching**
  // ---------------------------------------
  const handleUserSelect = async (user) => {
    const userId = user.user_id || user.id;
    console.log("Selected User ID:", userId);

    const selectedUserWithFullName = {
      ...user,
      fullName:
        `${user.first_name || ""} ${user.last_name || ""}`.trim() || user.email
    };
    setSelectedUser(selectedUserWithFullName);
    setIsEditingBillingInfo(false); // Reset editing state
    setShowSuccessMessage(false);
    setShowErrorMessage(false);

    // Initialize editBillingInfo with current user and billToInfo
    setEditBillingInfo({
      first_name: user.first_name || "",
      last_name: user.last_name || "",
      billing_code: user.billing_code || "",
      address: billToInfo.address || "",
      phone: billToInfo.phone || "",
      email: user.email || ""
    });

    setEvents([]); // Reset events
    setBillToInfo({
      name: "N/A",
      address: "N/A",
      phone: "N/A",
      email: "N/A"
    });

    if (!token) {
      console.error("No valid JWT token.");
      return;
    }

    try {
      // **Step 1: Fetch Events for the User**
      const eventsResponse = await axios.get(
        `${API_BASE_URL}/users/${userId}/events`,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );

      console.log("Fetched Events for User:", eventsResponse.data);
      const updatedEvents = eventsResponse.data.map((event) => ({
        ...event,
        // Calculate billing_hours if not provided
        billing_hours:
          (new Date(event.end_time) - new Date(event.start_time)) /
          (1000 * 60 * 60), // Calculate billing hours
        isEditing: false
      }));

      setEvents(updatedEvents);

      // **Step 2: Fetch Bill To information for the selected user**
      const billToResponse = await axios.get(
        `${API_BASE_URL}/invoicing/bill-to/${userId}`, // **Updated Endpoint**
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );

      const fetchedBillToInfo = billToResponse.data || {
        name: "N/A",
        address: "N/A",
        phone: "N/A",
        email: "N/A"
      };

      setBillToInfo(fetchedBillToInfo);
      setEditBillingInfo((prev) => ({
        ...prev,
        address: fetchedBillToInfo.address || "",
        phone: fetchedBillToInfo.phone || ""
      }));

      console.log("Fetched Bill To Info:", fetchedBillToInfo);

      // **Step 3: Fetch All Available Events**
      const allEventsResponse = await axios.get(
        `${API_BASE_URL}/invoicing/events`,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );

      setAllEvents(allEventsResponse.data.events);
      console.log("Fetched All Events:", allEventsResponse.data.events);
    } catch (error) {
      console.error("Error fetching events or Bill To info:", error);
      setErrorMessage(
        error.response?.data?.error ||
          "There was an error fetching events or billing information. Please try again."
      );
      setShowErrorMessage(true);
    }
  };

  // ---------------------------------------
  // **Handle Month Change and Reset Pagination**
  // ---------------------------------------
  const handleMonthChange = (increment) => {
    setCurrentMonth(currentMonth.clone().add(increment, "months"));
  };

  useEffect(() => {
    setCurrentAvailablePage(1);
  }, [currentMonth]);

  // ---------------------------------------
  // **Filter and Paginate Available Events**
  // ---------------------------------------
  const filteredAvailableEvents = allEvents.filter((event) => {
    const eventMonth = moment(event.start_time).startOf("month");
    return eventMonth.isSame(currentMonth, "month");
  });

  const totalAvailablePages = Math.ceil(
    filteredAvailableEvents.length / eventsPerPage
  );

  const indexOfLastEvent = currentAvailablePage * eventsPerPage;
  const indexOfFirstEvent = indexOfLastEvent - eventsPerPage;
  const currentAvailableEvents = filteredAvailableEvents.slice(
    indexOfFirstEvent,
    indexOfLastEvent
  );

  // ---------------------------------------
  // **Handle Delete Event**
  // ---------------------------------------
  const handleDeleteEvent = async (event) => {
    const confirmed = window.confirm(
      `Are you sure you want to delete the event "${event.title}"?`
    );
    if (!confirmed) return;

    try {
      await axios.delete(`${API_BASE_URL}/invoicing/events/${event.event_id}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      console.log(`✅ Deleted event with ID: ${event.event_id}`);

      // Update the frontend state to remove the deleted event
      setEvents((prevEvents) =>
        prevEvents.filter((e) => e.event_id !== event.event_id)
      );

      // Optionally, display a success message
      setShowSuccessMessage(true);
      setShowErrorMessage(false);

      // Reset the selected delete event ID
      setSelectedDeleteEventId(null);
    } catch (error) {
      console.error("Error deleting event:", error);
      alert(
        error.response?.data?.error ||
          "There was an error deleting the event. Please try again."
      );
      setErrorMessage(
        error.response?.data?.error ||
          "There was an error deleting the event. Please try again."
      );
      setShowErrorMessage(true);
    }
  };

  // ---------------------------------------
  // **Handle Email All Click**
  // ---------------------------------------
  const handleEmailAllClick = () => {
    setIsConfirmingEmailAll(true);
  };

  const handleSendEmailAll = async () => {
    try {
      // Close the confirmation dialog
      setIsConfirmingEmailAll(false);

      // Derive billingMonth and billingYear from currentMonth
      const billingMonth = currentMonth.month() + 1; // moment months are 0-indexed
      const billingYear = currentMonth.year();

      // Prepare the request payload
      const payload = {
        billingMonth: billingMonth,
        billingYear: billingYear
      };

      // Send the POST request to the backend
      const response = await axios.post(
        `${API_BASE_URL}/invoicing/send-invoice-email-all`, // **Updated Endpoint**
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json"
          }
        }
      );

      // Show success message
      alert(response.data.message);
      setShowSuccessMessage(true);
      setShowErrorMessage(false);
    } catch (error) {
      console.error("Error sending invoices to all users:", error);
      alert(
        error.response?.data?.error ||
          "There was an error sending invoices to all users. Please try again."
      );
      setErrorMessage(
        error.response?.data?.error ||
          "There was an error sending invoices to all users. Please try again."
      );
      setShowErrorMessage(true);
    }
  };

  const handleCancelEmailAll = () => {
    setIsConfirmingEmailAll(false);
  };

  // ---------------------------------------
  // **Preview Invoice Functionality**
  // ---------------------------------------
  const previewInvoice = async () => {
    if (!token) {
      alert("No JWT token found. Authentication failed.");
      return;
    }

    try {
      const userId = selectedUser?.user_id || selectedUser?.id;

      if (!userId) {
        alert("No user selected. Please select a user to preview the invoice.");
        return;
      }

      // Derive billingMonth and billingYear from currentMonth
      const billingMonth = currentMonth.month() + 1; // moment months are 0-indexed
      const billingYear = currentMonth.year();

      // Prepare the request payload with additional user info
      const payload = {
        user_id: userId,
        billingMonth: billingMonth,
        billingYear: billingYear,
        first_name: editBillingInfo.first_name,
        last_name: editBillingInfo.last_name,
        address: editBillingInfo.address,
        phone: editBillingInfo.phone,
        email: editBillingInfo.email
      };

      // **Log the payload for debugging**
      console.log("Preview Invoice Payload:", payload);

      // Request to generate a PDF preview
      const response = await axios.post(
        `${API_BASE_URL}/invoicing/invoice-pdf-preview`, // **Updated Endpoint**
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json"
          },
          responseType: "blob" // Expecting a blob response for PDF
        }
      );

      // Create a blob URL to display the PDF
      const pdfBlob = new Blob([response.data], { type: "application/pdf" });
      const pdfUrl = window.URL.createObjectURL(pdfBlob);
      window.open(pdfUrl, "_blank"); // Open in a new tab
    } catch (error) {
      console.error("Error previewing invoice:", error);
      alert(
        error.response?.data?.error ||
          "There was an error previewing the invoice. Please try again."
      );
    }
  };

  // ---------------------------------------
  // **Send Invoice to User**
  // ---------------------------------------
  const sendInvoiceToUser = async () => {
    try {
      const userId = selectedUser?.user_id || selectedUser?.id;
      if (!userId) {
        alert("No user selected. Please select a user to send the invoice.");
        return;
      }

      // Derive billingMonth and billingYear from currentMonth
      const billingMonth = currentMonth.month() + 1; // moment months are 0-indexed
      const billingYear = currentMonth.year();

      // Prepare the request payload
      const payload = {
        user_id: userId,
        billingMonth: billingMonth,
        billingYear: billingYear
      };

      // Send the POST request to the backend
      const response = await axios.post(
        `${API_BASE_URL}/invoicing/send-invoice-email`, // **Updated Endpoint**
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json"
          }
        }
      );

      // Show success message
      alert(response.data.message);
      setShowSuccessMessage(true);
      setShowErrorMessage(false);
    } catch (error) {
      console.error("Error sending invoice to user:", error);
      alert(
        error.response?.data?.error ||
          "There was an error sending the invoice to the user. Please try again."
      );
      setErrorMessage(
        error.response?.data?.error ||
          "There was an error sending the invoice to the user. Please try again."
      );
      setShowErrorMessage(true);
    }
  };

  // ---------------------------------------
  // **Helper Function to Get Events for Current Month**
  // ---------------------------------------
  const getEventsForCurrentMonth = () => {
    return events.filter((event) => {
      const eventMonth = moment(event.start_time).startOf("month");
      return eventMonth.isSame(currentMonth, "month");
    });
  };

  // ---------------------------------------
  // **Save Billing Information**
  // ---------------------------------------
  const handleSaveBillingInfoClick = () => {
    setShowConfirmSave(true);
  };

  const handleConfirmSaveBillingInfo = async () => {
    try {
      const userId = selectedUser.user_id || selectedUser.id;
      const payload = {
        first_name: editBillingInfo.first_name,
        last_name: editBillingInfo.last_name,
        billing_code: editBillingInfo.billing_code,
        address: editBillingInfo.address, // Added Address
        phone: editBillingInfo.phone,
        email: editBillingInfo.email
      };

      await axios.put(`${API_BASE_URL}/users/${userId}/billing-info`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      });

      setShowSuccessMessage(true);
      setShowErrorMessage(false);
      setShowConfirmSave(false);
      setIsEditingBillingInfo(false);
    } catch (error) {
      console.error("Error saving Billing Information:", error);
      setErrorMessage(
        error.response?.data?.error ||
          "There was an error saving the billing information. Please try again."
      );
      setShowErrorMessage(true);
      setShowConfirmSave(false);
    }
  };

  const handleCancelSaveBillingInfo = () => {
    setShowConfirmSave(false);
  };

  // ---------------------------------------
  // **Add Event Functionality**
  // ---------------------------------------
  const handleAddEventClick = () => {
    setIsAddingEvent(true);
    setNewEvent({
      title: "",
      price: "",
      description: "",
      start_time: "",
      end_time: "",
      billing_hours: ""
    });
    setShowErrorMessage(false);
    setShowSuccessMessage(false);
  };

  const handleNewEventChange = (field, value) => {
    setNewEvent((prev) => ({
      ...prev,
      [field]: value
    }));
  };

  const handleConfirmAddEvent = async () => {
    const { title, price, description, start_time, end_time, billing_hours } =
      newEvent;

    // Log the event data for debugging
    console.log("Attempting to add event with data:", {
      title,
      price,
      description,
      start_time,
      end_time,
      billing_hours
    });

    // Validate new event fields
    if (!title || !price || !start_time || !end_time || !billing_hours) {
      alert("Please fill in all required fields for the new event.");
      return;
    }

    try {
      // **Step 1: Create the Event in the Backend**
      const userId = selectedUser.user_id || selectedUser.id;

      const createEventPayload = {
        title,
        price: parseFloat(price),
        description: description || "",
        start_time: new Date(start_time).toISOString(),
        end_time: new Date(end_time).toISOString(),
        billing_hours: parseFloat(billing_hours),
        billing_rate: parseFloat(price), // Assuming billing_rate is the same as price per hour
        billing_code: selectedUser.billing_code || "" // Use the user's billing code if available
      };

      const createEventResponse = await axios.post(
        `${API_BASE_URL}/invoicing/events`, // **Updated Endpoint**
        createEventPayload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json"
          }
        }
      );

      const createdEvent = createEventResponse.data.event;
      console.log("Created Event:", createdEvent);

      // **Step 2: Register the Event to the Selected User**
      const registerResponse = await axios.post(
        `${API_BASE_URL}/invoicing/events/${createdEvent.id}/register`,
        { user_id: userId },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json"
          }
        }
      );

      console.log(
        `✅ Registered Event ID: ${createdEvent.id} to User ID: ${userId}`
      );
      alert("Event created and registered to user successfully.");

      // **Step 3: Update Frontend State**
      setEvents((prevEvents) => [
        ...prevEvents,
        {
          event_id: createdEvent.id, // Ensure this matches the backend response
          title: createdEvent.title,
          price: createdEvent.price,
          start_time: createdEvent.start_time,
          end_time: createdEvent.end_time,
          billing_hours: createdEvent.billing_hours,
          amount: createdEvent.amount, // Assuming backend calculates this
          description: createdEvent.description,
          isEditing: false
        }
      ]);

      // **Reset Add Event Form**
      setIsAddingEvent(false);
      setNewEvent({
        title: "",
        price: "",
        description: "",
        start_time: "",
        end_time: "",
        billing_hours: ""
      });
      setShowSuccessMessage(true);
      setShowErrorMessage(false);
    } catch (error) {
      console.error("Error adding and registering event:", error);
      alert(
        error.response?.data?.error ||
          "There was an error adding and registering the event. Please try again."
      );
      setErrorMessage(
        error.response?.data?.error ||
          "There was an error adding and registering the event. Please try again."
      );
      setShowErrorMessage(true);
    }
  };

  const handleCancelAddEvent = () => {
    setIsAddingEvent(false);
    setNewEvent({
      title: "",
      price: "",
      description: "",
      start_time: "",
      end_time: "",
      billing_hours: ""
    });
    setShowErrorMessage(false);
    setShowSuccessMessage(false);
  };

  // ---------------------------------------
  // **Render Method**
  // ---------------------------------------
  return (
    <div className="invoicing-container container mt-4">
      <h2 className="mb-4">Invoicing</h2>

      {/* ---------------------------------------
        **Email All Users Button**
      --------------------------------------- */}
      <div className="email-all-users mb-4">
        <button className="btn btn-danger w-100" onClick={handleEmailAllClick}>
          Email All Users for {currentMonth.format("MMMM YYYY")}
        </button>
      </div>

      {/* ---------------------------------------
        **Confirmation Dialog for Email All**
      --------------------------------------- */}
      {isConfirmingEmailAll && (
        <div className="confirmation-dialog card card-body mb-4">
          <p>
            This will send invoices to all users for{" "}
            {currentMonth.format("MMMM YYYY")}.
          </p>
          <button className="btn btn-success mr-2" onClick={handleSendEmailAll}>
            Send
          </button>
          <button className="btn btn-secondary" onClick={handleCancelEmailAll}>
            Cancel
          </button>
        </div>
      )}

      {/* ---------------------------------------
        **Success and Error Messages**
      --------------------------------------- */}
      {showSuccessMessage && (
        <div
          className="alert alert-success alert-dismissible fade show"
          role="alert"
        >
          Operation completed successfully.
          <button
            type="button"
            className="close"
            onClick={() => setShowSuccessMessage(false)}
          >
            <span>&times;</span>
          </button>
        </div>
      )}
      {showErrorMessage && (
        <div
          className="alert alert-danger alert-dismissible fade show"
          role="alert"
        >
          {errorMessage}
          <button
            type="button"
            className="close"
            onClick={() => setShowErrorMessage(false)}
          >
            <span>&times;</span>
          </button>
        </div>
      )}

      {/* ---------------------------------------
        **Users List with Search Bar and Enhanced Styling**
      --------------------------------------- */}
      <div className="user-list mb-4">
        <h3 className="mb-3">Users</h3>

        {/* **Search Bar** */}
        <div className="mb-3">
          <input
            type="text"
            className="form-control"
            placeholder="Search users..."
            value={searchQuery}
            onChange={handleSearchChange}
            aria-label="Search Users"
          />
        </div>

        {/* **Filtered Users List** */}
        <div className="card">
          <ul className="list-group list-group-flush">
            {users
              .filter((user) => {
                const displayName =
                  user.fullName ||
                  user.name?.trim() ||
                  `${user.first_name || ""} ${user.last_name || ""}`.trim() ||
                  user.email ||
                  "Unnamed User";
                return displayName
                  .toLowerCase()
                  .includes(searchQuery.toLowerCase());
              })
              .map((user) => {
                const displayName =
                  user.fullName ||
                  user.name?.trim() ||
                  `${user.first_name || ""} ${user.last_name || ""}`.trim() ||
                  user.email ||
                  "Unnamed User";

                // Determine if this user is the selected user
                const isSelected =
                  (selectedUser?.user_id &&
                    selectedUser.user_id === user.user_id) ||
                  (selectedUser?.id && selectedUser.id === user.id);

                return (
                  <li
                    key={user.user_id || user.id}
                    className={`list-group-item ${
                      isSelected ? "selected-user" : ""
                    }`}
                    onClick={() => handleUserSelect(user)}
                    style={{ cursor: "pointer" }}
                    title={`Select ${displayName}`} // Tooltip for accessibility
                  >
                    <div className="d-flex justify-content-between align-items-center">
                      <span>{displayName}</span>
                      {/* Add the "Selected" badge only if this user is selected */}
                      {isSelected && (
                        <span className="badge badge-primary badge-pill">
                          Selected
                        </span>
                      )}
                    </div>
                  </li>
                );
              })}
            {users.filter((user) => {
              const displayName =
                user.fullName ||
                user.name?.trim() ||
                `${user.first_name || ""} ${user.last_name || ""}`.trim() ||
                user.email ||
                "Unnamed User";
              return displayName
                .toLowerCase()
                .includes(searchQuery.toLowerCase());
            }).length === 0 && (
              <li className="list-group-item text-center text-muted">
                No users found.
              </li>
            )}
          </ul>
        </div>
      </div>

      {/* ---------------------------------------
        **Selected User's Events and Available Events**
      --------------------------------------- */}
      {selectedUser && (
        <div className="user-events">
          <h3>
            {selectedUser.fullName || selectedUser.email}'s Events for{" "}
            {currentMonth.format("MMMM YYYY")}
          </h3>

          {/* ---------------------------------------
            **Edit Billing Information Button**
          --------------------------------------- */}
          <button
            className="btn btn-warning mb-3"
            onClick={() => setIsEditingBillingInfo(!isEditingBillingInfo)}
          >
            {isEditingBillingInfo
              ? `Cancel Edit`
              : `Edit ${selectedUser.first_name} ${selectedUser.last_name}'s Billing Information`}
          </button>

          {/* ---------------------------------------
            **Billing Information Edit Form**
          --------------------------------------- */}
          {isEditingBillingInfo && (
            <div className="card card-body mb-3">
              <h4>Edit Billing Information</h4>

              {/* ---------------------------------------
                **Billing Information Fields**
              --------------------------------------- */}
              <div className="form-group">
                <label>First Name *</label>
                <input
                  type="text"
                  value={editBillingInfo.first_name}
                  onChange={(e) =>
                    setEditBillingInfo({
                      ...editBillingInfo,
                      first_name: e.target.value
                    })
                  }
                  className="form-control"
                  required
                />
              </div>
              <div className="form-group">
                <label>Last Name *</label>
                <input
                  type="text"
                  value={editBillingInfo.last_name}
                  onChange={(e) =>
                    setEditBillingInfo({
                      ...editBillingInfo,
                      last_name: e.target.value
                    })
                  }
                  className="form-control"
                  required
                />
              </div>
              <div className="form-group">
                <label>Address *</label>
                <input
                  type="text"
                  value={editBillingInfo.address}
                  onChange={(e) =>
                    setEditBillingInfo({
                      ...editBillingInfo,
                      address: e.target.value
                    })
                  }
                  className="form-control"
                  required
                />
              </div>
              <div className="form-group">
                <label>Phone Number *</label>
                <input
                  type="text"
                  value={editBillingInfo.phone}
                  onChange={(e) =>
                    setEditBillingInfo({
                      ...editBillingInfo,
                      phone: e.target.value
                    })
                  }
                  className="form-control"
                  required
                />
              </div>
              <div className="form-group">
                <label>Email *</label>
                <input
                  type="email"
                  value={editBillingInfo.email}
                  onChange={(e) =>
                    setEditBillingInfo({
                      ...editBillingInfo,
                      email: e.target.value
                    })
                  }
                  className="form-control"
                  required
                />
              </div>
              <div className="form-group">
                <label>Billing Code *</label>
                <input
                  type="text"
                  value={editBillingInfo.billing_code}
                  onChange={(e) =>
                    setEditBillingInfo({
                      ...editBillingInfo,
                      billing_code: e.target.value
                    })
                  }
                  className="form-control"
                  required
                />
              </div>

              {/* ---------------------------------------
                **Save Billing Information Button**
              --------------------------------------- */}
              <button
                className="btn btn-success mt-3"
                onClick={handleSaveBillingInfoClick}
              >
                Save Billing Information
              </button>

              {/* ---------------------------------------
                **Confirmation Dialog for Save Billing Info**
              --------------------------------------- */}
              {showConfirmSave && (
                <div className="confirmation-message card card-body mt-3">
                  <p>Are you sure you want to save these changes?</p>
                  <button
                    className="btn btn-primary mr-2"
                    onClick={handleConfirmSaveBillingInfo}
                  >
                    Confirm
                  </button>
                  <button
                    className="btn btn-secondary"
                    onClick={handleCancelSaveBillingInfo}
                  >
                    Cancel
                  </button>
                </div>
              )}
            </div>
          )}

          {/* ---------------------------------------
            **Add Event for User Button**
          --------------------------------------- */}
          <button
            className="btn btn-primary mb-3"
            onClick={handleAddEventClick}
          >
            Add Event
          </button>

          {/* ---------------------------------------
            **Add Event Form**
          --------------------------------------- */}
          {isAddingEvent && (
            <div className="card card-body mb-3">
              <h4>Add New Event</h4>
              <div className="form-group">
                <label>Title *</label>
                <input
                  type="text"
                  value={newEvent.title}
                  onChange={(e) =>
                    handleNewEventChange("title", e.target.value)
                  }
                  className="form-control"
                  required
                />
              </div>
              <div className="form-group">
                <label>Start Date & Time *</label>
                <input
                  type="datetime-local"
                  value={newEvent.start_time}
                  onChange={(e) =>
                    handleNewEventChange("start_time", e.target.value)
                  }
                  className="form-control"
                  required
                />
              </div>
              <div className="form-group">
                <label>End Date & Time *</label>
                <input
                  type="datetime-local"
                  value={newEvent.end_time}
                  onChange={(e) =>
                    handleNewEventChange("end_time", e.target.value)
                  }
                  className="form-control"
                  required
                />
              </div>
              <div className="form-group">
                <label>Number of Hours *</label>
                <input
                  type="number"
                  step="0.01"
                  value={newEvent.billing_hours}
                  onChange={(e) =>
                    handleNewEventChange("billing_hours", e.target.value)
                  }
                  className="form-control"
                  required
                />
              </div>
              <div className="form-group">
                <label>Price *</label>
                <input
                  type="number"
                  step="0.01"
                  value={newEvent.price}
                  onChange={(e) =>
                    handleNewEventChange("price", e.target.value)
                  }
                  className="form-control"
                  required
                />
              </div>
              <div className="form-group">
                <label>Description (Optional)</label>
                <textarea
                  value={newEvent.description}
                  onChange={(e) =>
                    handleNewEventChange("description", e.target.value)
                  }
                  className="form-control"
                  rows="3"
                ></textarea>
              </div>
              <button
                className="btn btn-success mt-3 mr-2"
                onClick={() => setShowConfirmAddEvent(true)}
              >
                Add Event
              </button>
              <button
                className="btn btn-secondary mt-3"
                onClick={handleCancelAddEvent}
              >
                Cancel
              </button>

              {/* ---------------------------------------
                **Confirmation Dialog for Add Event**
              --------------------------------------- */}
              {showConfirmAddEvent && (
                <div className="confirmation-dialog card card-body mt-3">
                  <p>Are you sure you want to add this event?</p>
                  <button
                    className="btn btn-primary mr-2"
                    onClick={handleConfirmAddEvent}
                  >
                    Confirm
                  </button>
                  <button
                    className="btn btn-secondary"
                    onClick={() => setShowConfirmAddEvent(false)}
                  >
                    Cancel
                  </button>
                </div>
              )}
            </div>
          )}

          {/* ---------------------------------------
            **Month Navigation**
          --------------------------------------- */}
          <div className="month-navigation mb-4 d-flex justify-content-between">
            <button
              className="btn btn-outline-primary"
              onClick={() => handleMonthChange(-1)}
            >
              Previous Month
            </button>
            <span className="current-month h4 my-auto">
              {currentMonth.format("MMMM YYYY")}
            </span>
            <button
              className="btn btn-outline-primary"
              onClick={() => handleMonthChange(1)}
            >
              Next Month
            </button>
          </div>

          {/* ---------------------------------------
            **Registered and Available Events**
          --------------------------------------- */}
          <div className="row">
            {/* Registered Events */}
            <div className="col-md-6">
              <h4>Registered Events</h4>
              {getEventsForCurrentMonth().length > 0 ? (
                <ul className="list-group">
                  {getEventsForCurrentMonth().map((event) => (
                    <li
                      key={event.event_id}
                      className="list-group-item"
                      onClick={() =>
                        setSelectedDeleteEventId(
                          selectedDeleteEventId === event.event_id
                            ? null
                            : event.event_id
                        )
                      }
                      style={{ cursor: "pointer" }}
                    >
                      <div className="d-flex justify-content-between align-items-center">
                        <div>
                          <strong>{event.title}</strong>
                          <br />
                          <small>
                            {moment(event.start_time).format(
                              "MM/DD/YYYY HH:mm"
                            )}{" "}
                            -{" "}
                            {moment(event.end_time).format("MM/DD/YYYY HH:mm")}
                          </small>
                          <br />
                          <span>Hours: {event.billing_hours}</span>
                        </div>
                        {/* Delete Button */}
                        {selectedDeleteEventId === event.event_id && (
                          <button
                            className="btn btn-danger btn-sm"
                            onClick={(e) => {
                              e.stopPropagation(); // Prevent triggering the parent onClick
                              handleDeleteEvent(event);
                            }}
                          >
                            Delete
                          </button>
                        )}
                      </div>
                    </li>
                  ))}
                </ul>
              ) : (
                <p>No events registered for this month.</p>
              )}
            </div>

            {/* Available Events */}
            <div className="col-md-6">
              <h4>Available Events for {currentMonth.format("MMMM YYYY")}</h4>
              {currentAvailableEvents.length > 0 ? (
                <ul className="list-group">
                  {currentAvailableEvents.map((event) => {
                    // Check if the event is already registered to the user
                    const isRegistered = events.some(
                      (userEvent) => userEvent.event_id === event.id
                    );
                    return (
                      <li
                        key={event.id}
                        className="list-group-item d-flex justify-content-between align-items-center"
                      >
                        <div>
                          <strong>{event.title}</strong>
                          <br />
                          <small>
                            {moment(event.start_time).format(
                              "MM/DD/YYYY HH:mm"
                            )}{" "}
                            -{" "}
                            {moment(event.end_time).format("MM/DD/YYYY HH:mm")}
                          </small>
                        </div>
                        <button
                          className="btn btn-primary btn-sm"
                          onClick={() => handleRegisterEvent(event)}
                          disabled={isRegistered} // Disable if already registered
                        >
                          {isRegistered ? "Registered" : "Register"}
                        </button>
                      </li>
                    );
                  })}
                </ul>
              ) : (
                <p>No available events for this month.</p>
              )}

              {/* ---------------------------------------
                **Pagination Controls for Available Events**
              --------------------------------------- */}
              {filteredAvailableEvents.length > eventsPerPage && (
                <div className="d-flex justify-content-center mt-3">
                  {/* Replaced <nav> with <div> and added a unique class name */}
                  <div className="available-events-pagination">
                    <ul className="pagination">
                      <li
                        className={`page-item ${
                          currentAvailablePage === 1 ? "disabled" : ""
                        }`}
                      >
                        <button
                          className="page-link"
                          onClick={() =>
                            setCurrentAvailablePage(currentAvailablePage - 1)
                          }
                          disabled={currentAvailablePage === 1}
                          aria-label="Previous Page"
                        >
                          Previous
                        </button>
                      </li>
                      {Array.from(
                        { length: totalAvailablePages },
                        (_, index) => index + 1
                      ).map((page) => (
                        <li
                          key={page}
                          className={`page-item ${
                            currentAvailablePage === page ? "active" : ""
                          }`}
                        >
                          <button
                            className="page-link"
                            onClick={() => setCurrentAvailablePage(page)}
                          >
                            {page}
                          </button>
                        </li>
                      ))}
                      <li
                        className={`page-item ${
                          currentAvailablePage === totalAvailablePages
                            ? "disabled"
                            : ""
                        }`}
                      >
                        <button
                          className="page-link"
                          onClick={() =>
                            setCurrentAvailablePage(currentAvailablePage + 1)
                          }
                          disabled={
                            currentAvailablePage === totalAvailablePages
                          }
                          aria-label="Next Page"
                        >
                          Next
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
              )}
            </div>
          </div>

          {/* ---------------------------------------
            **Show Preview Button**
          --------------------------------------- */}
          {events.length > 0 && (
            <div className="d-flex justify-content-center mb-3 mt-4">
              <button className="btn btn-primary" onClick={previewInvoice}>
                Show Preview of {editBillingInfo.first_name}{" "}
                {editBillingInfo.last_name} Invoice for{" "}
                {currentMonth.format("MMMM YYYY")}
              </button>
            </div>
          )}

          {/* ---------------------------------------
            **Send Invoice Email Button**
          --------------------------------------- */}
          {events.length > 0 && (
            <div className="d-flex justify-content-center mb-3">
              <button className="btn btn-secondary" onClick={sendInvoiceToUser}>
                Send {editBillingInfo.first_name} {editBillingInfo.last_name}{" "}
                Invoice for {currentMonth.format("MMMM YYYY")}
              </button>
            </div>
          )}

          {/* ---------------------------------------
            **Confirmation Dialog for Delete Event**
          --------------------------------------- */}
          {showConfirmDelete && eventToDelete && (
            <div className="confirmation-dialog card card-body mt-4">
              <p>
                Are you sure you want to delete the event "{eventToDelete.title}
                "?
              </p>
              <button
                className="btn btn-danger mr-2"
                onClick={() => handleDeleteEvent(eventToDelete)}
              >
                Yes, Delete
              </button>
              <button
                className="btn btn-secondary"
                onClick={() => setShowConfirmDelete(false)}
              >
                Cancel
              </button>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Invoicing;
