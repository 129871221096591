import React, { useState, useEffect } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import "./UserList.css";
import "bootstrap/dist/css/bootstrap.min.css";

const UserList = () => {
  const { eventId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [users, setUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  const eventDetails = location.state?.eventDetails || null;

  useEffect(() => {
    if (!eventDetails) {
      console.error("No event details provided");
      navigate("/classLists");
      return;
    }

    const token = localStorage.getItem("token");
    if (!token) {
      console.error("No token found, authorization required");
      navigate("/classLists");
      return;
    }

    const authHeaders = { headers: { Authorization: `Bearer ${token}` } };
    const usersForEventEndpoint = `https://www.ptemployment.org/api/events/${eventId}/registrations`;
    const participationFormsEndpoint = `https://www.ptemployment.org/api/participation-waivers`;

    axios
      .get(participationFormsEndpoint, authHeaders)
      .then((participationResponse) => {
        const participationFormsData = participationResponse.data;
        return axios
          .get(usersForEventEndpoint, authHeaders)
          .then((usersResponse) => {
            const enrichedUsers = usersResponse.data.map((user) => {
              const userForm = participationFormsData.find(
                (form) => form.user_id === user.user_id
              );
              return {
                ...user,
                firstName: userForm?.first_name || user.first_name || "N/A",
                lastName: userForm?.last_name || user.last_name || "N/A"
              };
            });
            setUsers(enrichedUsers);
          });
      })
      .catch((error) => {
        console.error("Error fetching user data:", error);
      });
  }, [eventId, eventDetails, navigate]);

  const handlePrint = () => {
    window.print();
  };

  // Filter users based on search term
  const filteredUsers = users.filter((user) => {
    const fullName = `${user.firstName} ${user.lastName}`.toLowerCase();
    return fullName.includes(searchTerm.toLowerCase());
  });

  return (
    <div className="user-list-container">
      {/* Navigation and Print Buttons */}
      <div className="mb-3 print-hide navigation-buttons">
        <button
          onClick={() => navigate("/userProfile")}
          className="btn btn-primary me-2 mb-2"
        >
          Back to Profile
        </button>
        <button
          onClick={() => navigate("/classLists")}
          className="btn btn-info me-2 mb-2"
        >
          Back to Class Lists
        </button>
        <button onClick={handlePrint} className="btn btn-warning mb-2">
          Print
        </button>
      </div>

      <div className="card">
        {/* Event Details */}
        <div className="card-header custom-header">
          <h2 className="h4 mb-0">
            Users Registered for "{eventDetails?.title || "Event"}"
          </h2>
          <p className="custom-date-time mb-0">
            <strong>Date:</strong>{" "}
            {eventDetails?.start_time
              ? new Date(eventDetails.start_time).toLocaleDateString()
              : "Date not available"}{" "}
            | <strong>Time:</strong>{" "}
            {eventDetails?.start_time
              ? new Date(eventDetails.start_time).toLocaleTimeString()
              : "Time not available"}
          </p>
        </div>

        <div className="card-body">
          {/* Search Bar */}
          <div className="mb-3 print-hide">
            <input
              type="text"
              placeholder="Search users..."
              className="form-control"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>

          {/* User Table */}
          <div className="table-responsive">
            <table className="table table-striped table-hover">
              <thead className="table-dark">
                <tr>
                  <th>#</th>
                  <th>Name</th>
                  <th>Role</th>
                  <th>Username</th>
                  <th>Email</th>
                </tr>
              </thead>
              <tbody>
                {filteredUsers.map((user, index) => (
                  <tr key={index}>
                    <td data-label="#">{index + 1}</td>
                    <td data-label="Name">
                      {user.firstName} {user.lastName}
                    </td>
                    <td data-label="Role">{user.role}</td>
                    <td data-label="Username">{user.username}</td>
                    <td data-label="Email">{user.email}</td>
                  </tr>
                ))}
                {/* Show a message if no users are found */}
                {filteredUsers.length === 0 && (
                  <tr>
                    <td colSpan="5" className="text-center">
                      No users found.
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserList;
