// src/components/HeroSection/HeroSection.js

import React from "react";
import "./HeroSection.css";

const HeroSection = () => {
  return (
    <div className="hero-section">
      <img
        src="/Assets/Photos/hero1.jpg"
        alt="Dream A Bigger Dream"
        className="hero-image"
        loading="lazy" /* Enables lazy loading */
      />
      <div className="hero-text">
        <h1>Dream A Bigger Dream</h1>
        <p>
          Our Goal? To help clients achieve independence through employment.
        </p>
      </div>
    </div>
  );
};

export default HeroSection;
