import React, { useState, useEffect } from "react";
import axios from "axios";
import "./ClassLists.css";
import { useNavigate } from "react-router-dom";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";

const ClassLists = () => {
  const [events, setEvents] = useState([]);
  const [showPastClasses, setShowPastClasses] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [currentDate, setCurrentDate] = useState(new Date());

  const navigate = useNavigate();

  useEffect(() => {
    const eventsEndpoint = `https://www.ptemployment.org/api/classes/class-schedules/`;

    axios
      .get(eventsEndpoint)
      .then((response) => {
        const sortedEvents = response.data.sort((a, b) => {
          const dateA = new Date(a.start_time);
          const dateB = new Date(b.start_time);
          return dateA - dateB;
        });
        setEvents(sortedEvents);
      })
      .catch((error) => console.error("Error fetching events:", error));
  }, []);

  const toggleShowPastClasses = () => {
    setShowPastClasses(!showPastClasses);
  };

  const handleUserListNavigation = (event) => {
    navigate(`/userList/${event.id}`, { state: { eventDetails: event } });
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value.toLowerCase());
  };

  const handlePrevMonth = () => {
    const prevMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() - 1,
      1
    );
    setCurrentDate(prevMonth);
  };

  const handleNextMonth = () => {
    const nextMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() + 1,
      1
    );
    setCurrentDate(nextMonth);
  };

  const currentTime = new Date();

  // Filter and group events
  const filteredEvents = events.filter((event) => {
    const eventTitle = event.title.toLowerCase();
    const eventDateStr = new Date(event.start_time)
      .toLocaleDateString()
      .toLowerCase();
    const eventTimeStr = new Date(event.start_time)
      .toLocaleTimeString()
      .toLowerCase();
    return (
      eventTitle.includes(searchQuery) ||
      eventDateStr.includes(searchQuery) ||
      eventTimeStr.includes(searchQuery)
    );
  });

  const eventsByMonth = filteredEvents.reduce((acc, event) => {
    const eventDate = new Date(event.start_time);
    const monthYear = eventDate.toLocaleString("default", {
      month: "long",
      year: "numeric"
    });

    // Filter past events if not showing past classes
    if (!showPastClasses && eventDate < currentTime) {
      return acc;
    }

    // Only include events for the current month
    if (
      eventDate.getMonth() === currentDate.getMonth() &&
      eventDate.getFullYear() === currentDate.getFullYear()
    ) {
      if (!acc[monthYear]) {
        acc[monthYear] = [];
      }
      acc[monthYear].push(event);
    }

    return acc;
  }, {});

  const monthYearStr = currentDate.toLocaleString("default", {
    month: "long",
    year: "numeric"
  });

  const eventsInCurrentMonth = eventsByMonth[monthYearStr] || [];

  const isCurrentMonthPast =
    currentDate.getMonth() < currentTime.getMonth() ||
    currentDate.getFullYear() < currentTime.getFullYear();

  return (
    <div className="class-lists-container">
      {/* Back to Profile Button */}
      <div className="mb-3">
        <button
          onClick={() => navigate("/userProfile")}
          className="btn btn-primary"
        >
          Back to Profile
        </button>
      </div>

      <h1 className="class-lists-title">Class Lists</h1>

      {/* Controls */}
      <div className="class-lists-controls mb-3">
        <button className="btn btn-info me-2" onClick={toggleShowPastClasses}>
          {showPastClasses ? "Hide Past Classes" : "Show Past Classes"}
        </button>
        <input
          type="text"
          className="form-control d-inline w-auto"
          placeholder="Search classes by name or date..."
          onChange={handleSearchChange}
        />
      </div>

      {/* Month Navigation */}
      <div className="month-navigation mb-3">
        <button className="btn btn-light me-2" onClick={handlePrevMonth}>
          <FaChevronLeft /> Prev
        </button>
        <h2 className="month-title d-inline">{monthYearStr}</h2>
        <button className="btn btn-light ms-2" onClick={handleNextMonth}>
          Next <FaChevronRight />
        </button>
      </div>

      {/* Alert for Past Months */}
      {!showPastClasses && isCurrentMonthPast && (
        <div className="alert alert-warning text-center">
          You are viewing past months. Click "Show Past Classes" to display
          classes from this month.
        </div>
      )}

      {/* Event List */}
      {eventsInCurrentMonth.length > 0 ? (
        eventsInCurrentMonth.map((event) => (
          <div key={event.id} className="event-item">
            <span className="event-info">
              {event.title} - {new Date(event.start_time).toLocaleDateString()}{" "}
              {new Date(event.start_time).toLocaleTimeString()}
            </span>
            <button
              className="btn btn-primary"
              onClick={() => handleUserListNavigation(event)}
            >
              User List
            </button>
          </div>
        ))
      ) : (
        <div className="no-events">No classes scheduled for this month.</div>
      )}
    </div>
  );
};

export default ClassLists;
